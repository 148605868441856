import styles from './Spinner.module.css';

export default function Spinner() {
  return (
    <div className={styles.progress_bar_container}>
      <style jsx global>{`
        body {
          overflow: hidden;
        }
      `}</style>
      <div className={styles.progress_bar_wrapper}>
        <h1>Processing</h1>
        <div className={styles.spinner}>
          <div className={styles.rect1}></div>
          <div className={styles.rect2}></div>
          <div className={styles.rect3}></div>
          <div className={styles.rect4}></div>
          <div className={styles.rect5}></div>
        </div>
      </div>
    </div>
  );
}
