import FetchError from './fetch-error';

const fetcher = (...args) =>
  fetch(...args).then(async res => {
    if (!res.ok) {
      let headers;
      if (res.headers) headers = Object.fromEntries(res.headers.entries());
      let body;
      if (res.body) {
        if (res.headers.get('Content-Type')?.includes('application/json')) {
          const json = await res.json();
          body = JSON.stringify(json);
        } else {
          body = await res.text();
        }
      }

      throw new FetchError({ statusCode: res.status, response: res, headers, body });
    }

    const contentType = res.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) return res.json();
    return res.text();
  });

export default fetcher;
