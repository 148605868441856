import { createContext, useContext } from 'react';

const PreviewDataContext = createContext();

const PreviewDataProvider = ({ preview, children }) => {
  return <PreviewDataContext.Provider value={{ preview }}>{children}</PreviewDataContext.Provider>;
};

const usePreviewData = () => {
  const context = useContext(PreviewDataContext);
  if (context === undefined) {
    throw new Error('usePreviewData must be used within a PreviewData');
  }

  return context;
};

export { PreviewDataProvider, usePreviewData };
