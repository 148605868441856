import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import styles from './RatingStars.module.css';
import { buildCssVar } from 'utils/style-override';

const propTypes = {
  rating: PropTypes.number.isRequired,
  className: PropTypes.string,
  starColor: PropTypes.string,
  starEmptyColor: PropTypes.string,
};

const defaultProps = {
  className: null,
  starColor: null,
  starEmptyColor: null,
};

export default function HeroContainer({ rating, className, starColor, starEmptyColor }) {
  const { fullStars, emptyStars, useHalf } = useMemo(() => {
    const result = {
      fullStars: Math.floor(rating),
      emptyStars: 5 - Math.ceil(rating),
      useHalf: false,
    };
    const decimal = (rating - result.fullStars).toFixed(1);

    if (decimal > 0.5) {
      // Use full star if > 0.5
      result.fullStars += 1;
    } else if (decimal > 0) {
      result.useHalf = true;
    }

    return result;
  }, [rating]);

  return (
    <div className={`root ${className}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--star-color', starColor, 'var(--default-star-color)')}
          ${buildCssVar('--star-empty-color', starEmptyColor, 'var(--default-star-empty-color)')}
        }
      `}</style>

      {[...Array(fullStars)].map((item, index) => {
        return <FaStar key={index} className={styles.yellow_star} />;
      })}
      {useHalf && <FaStarHalfAlt className={styles.yellow_star} />}
      {[...Array(emptyStars)].map((item, index) => {
        return <FaStar className={styles.grey_star} key={index} />;
      })}
    </div>
  );
}

HeroContainer.propTypes = propTypes;
HeroContainer.defaultProps = defaultProps;
