import { useFunnelData } from 'utils/funnel-data-context';

export default function DynamicBody() {
  const { rootDomain, googleTagManagerId, googleTagManagerAuthCode, googleTagManagerPreview } =
    useFunnelData();
  return (
    <>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src={`https://${
            rootDomain ? `collect.${rootDomain}` : 'www.googletagmanager.com'
          }/ns.html?id=${googleTagManagerId}${
            process.env.APP_ENV !== 'production'
              ? `&gtm_auth=${googleTagManagerAuthCode}&gtm_preview=${googleTagManagerPreview}&gtm_cookies_win=x`
              : ''
          }`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
}
