export default function PreviewModeBanner() {
  // TODO: Use router.asPath once we stop relying on URL rewrite
  return (
    <div style={{ background: 'red', textAlign: 'center' }}>
      Preview mode is on.{' '}
      <a style={{ textDecoration: 'underline' }} href={'/api/exit-preview'}>
        Turn Off
      </a>
    </div>
  );
}
