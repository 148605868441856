export default class FetchError extends Error {
  constructor({ statusCode, response, headers, body } = {}) {
    super(`${response.statusText} ${response.url}`);

    this.type = 'FetchError';
    this.name = this.constructor.name;
    this.data = { ...response, headers, body };
    this.statusCode = statusCode;
  }
}
