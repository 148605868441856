import { useFunnelData } from 'utils/funnel-data-context';
import Script from 'next/script';

export default function DynamicBottom() {
  const { klaviyoPublicKey } = useFunnelData();
  return (
    <>
      {klaviyoPublicKey && (
        <Script
          id="klaviyo-script"
          strategy="worker"
          type="text/javascript"
          src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoPublicKey}`}
        />
      )}
    </>
  );
}
