export function missingPageOffer(directusProductId, preview) {
  const message = directusProductId
    ? `Directus CMS data includes Product ID: ${directusProductId}, but matching Velocity Admin Page Offer was not found. Make sure your Velocity Admin Funnel Page has Page Offer(s).`
    : 'Offer not found. Make sure your Velocity Admin Funnel Page has a Page Offer.';
  if (!preview) throw new Error(message);
  console.warn(message);
}

export function missingComponentField(componentName, fieldName, preview) {
  const message = `The ${componentName} item is missing a ${fieldName}! Please make sure this is set in Directus or Velocity.`;
  if (!preview) throw new Error(message);
  console.warn(message);
}
