import styles from './BulletList.module.css';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useTextFontOverrides } from 'utils/font-override';
import Bullet, { queryTemplate as bulletQueryTemplate } from './Bullet';
import { buildCssVar } from 'utils/style-override';

const MarkdownText = dynamic(() => import('./MarkdownText'));

export const queryTemplate = {
  '... on BulletList': [
    'text',
    {
      bullets: [
        {
          item: [bulletQueryTemplate],
        },
      ],
    },
    'textColor',
    'backgroundColor',
    { textFont: ['family'] },
  ],
};

const propTypes = {
  text: PropTypes.string,
  bullets: PropTypes.arrayOf(PropTypes.shape({ item: PropTypes.shape(Bullet.propTypes) })),
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
};

const defaultProps = {
  text: null,
  bullets: [],
  textColor: null,
  backgroundColor: null,
  textFont: null,
};

export default function BulletList({
  text,
  formattedText,
  bullets,
  textColor,
  backgroundColor,
  textFont,
}) {
  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <div className={`root ${styles.container}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--text-color', textColor, 'var(--text-color-dark)')}
          ${buildCssVar('--background-color', backgroundColor, 'var(--default-background-color)')}
          ${textFontOverrides ?? ''}
        }
      `}</style>

      {text && (
        <div className={styles.text}>
          {!formattedText && <MarkdownText text={text}></MarkdownText>}
          {formattedText && <div dangerouslySetInnerHTML={{ __html: formattedText }} />}
        </div>
      )}

      <div className={styles.bullets_wrapper}>
        {bullets.map(({ item }, i) => (
          <Bullet key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

BulletList.propTypes = propTypes;
BulletList.defaultProps = defaultProps;
