import cookie from 'cookie';
import fetcher from './fetcher';

export function matterIdentify({ customerData, apiBaseUrl, funnelId, subscribeSms }) {
  try {
    const cookies = cookie.parse(document.cookie ?? '');
    const { __kla_id: klaviyoCookie } = cookies;

    return fetcher(`${apiBaseUrl}/identify`, {
      // Needed for CORS request to send cookies
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerData, funnelId, subscribeSms, klaviyoCookie }),
    });
  } catch (err) {
    console.warn(`Identify failed: ${err.statusCode}`);
  }
}
