import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './LazyAutoplayVideo.module.css';
import Image from './Image';
import { getDirectusFileUrl } from 'utils/directus';
import { usePreviewData } from 'utils/preview-data-context';
import { missingComponentField } from 'utils/error';

const propTypes = {
  alt: PropTypes.string.isRequired,
  placeholderImage: PropTypes.object,
  video: PropTypes.shape({
    type: PropTypes.string,
    filename_disk: PropTypes.string,
    filesize: PropTypes.string,
  }).isRequired,
  showVideoControls: PropTypes.bool,
  shouldPlay: PropTypes.bool,
};

const defaultProps = {
  showVideoControls: false,
  shouldPlay: true,
};

/**
 * Use this component for a video to replace animated gifs. Video file will be
 * lazy loaded and will autoplay.
 */
export default function LazyAutoplayVideo({
  alt,
  placeholderImage,
  video,
  showVideoControls,
  shouldPlay,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { preview } = usePreviewData();

  useEffect(() => {
    if (!video) missingComponentField('autoplay video', 'video file', preview);
  }, []);

  const ref = useRef();

  const { videoSrc, type } = useMemo(
    () => ({
      videoSrc: getDirectusFileUrl(video),
      type: video?.type,
    }),
    [video]
  );

  useEffect(() => {
    const lazyVideo = ref.current;
    let lazyVideoObserver;
    let removeEventListener;

    const handleVideoLoaded = () => {
      setIsLoaded(true);
    };

    if ('IntersectionObserver' in window) {
      lazyVideoObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            for (const source in entry.target.children) {
              const videoSource = entry.target.children[source];
              if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                videoSource.src = videoSource.dataset.src;
              }
            }

            entry.target.addEventListener('loadeddata', handleVideoLoaded);
            removeEventListener = entry.target.removeEventListener;

            entry.target.load();
            lazyVideoObserver.unobserve(entry.target);
          }
        });
      });

      lazyVideoObserver.observe(lazyVideo);
    }

    return () => {
      if (lazyVideoObserver) lazyVideoObserver.unobserve(lazyVideo);
      if (removeEventListener) removeEventListener('loadeddata', handleVideoLoaded);
    };
  }, []);

  const videoProps = {
    className: `${isLoaded ? styles.show : styles.hide}`,
    ref,
    alt,
    controls: showVideoControls,
    playsInline: true,
    muted: true,
    loop: true,
  };
  // To disable video autoplay, autoplay="false" will not work; the video will autoplay if the
  // attribute is there in the <video> tag at all. To remove autoplay, the attribute needs to be
  // removed altogether.
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
  if (shouldPlay) videoProps.autoPlay = true;

  return (
    <div className={styles.container}>
      <video {...videoProps}>
        <source data-src={videoSrc} type={type} />
      </video>
      { placeholderImage && (
        <div className={`${isLoaded ? styles.hide : styles.show} ${styles.image_wrapper}`}>
          <Image className={styles.image} src={placeholderImage} alt={alt} />
        </div>
      ) }
    </div>
  );
}

LazyAutoplayVideo.propTypes = propTypes;
LazyAutoplayVideo.defaultProps = defaultProps;
